import Vue from "vue";
// import axios from "axios"
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

export const state = () => ({
  salesNo: "",
});

export const mutations = {
  UPDATE_SALES_NO: (state, payload) => {
    state.salesNo = payload;
  },
};

export const actions = {
  setSalesNo(context, payload) {
    context.commit("UPDATE_SALES_NO", payload);
  },
};
export const getters = {
  getSalesNo: (state) => {
    return state.salesNo;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
