import Vue from "vue";
// import axios from "axios"
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

export const state = () => ({
  state: {
    isCenterPoint: "1",
  },
});

export const mutations = {};

export const actions = {};
export const getters = {};

export default {
  state,
  actions,
  mutations,
  getters,
};
