import Vue from 'vue'
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

export const state = () => ({
  state: {
    selectedItem: {
      redeem_order_id: 0,
      user_id: 0,
      user_guid: "",
      product_type: 0,
      redeem_name: "",
      redeem_id: 0,
      imge: "",
      image_url: null,
      image_path: null,
      gift: "",
      product_name: null,
      member_id: "",
      name: "",
      first_name_th: "",
      last_name_th: "",
      telephone: "",
      used_point: 0,
      point_used: 0,
      branch_id: 0,
      branch_name: "",
      redeem_date: "",
      status: 0,
      redeem_skip: 0,
      ready_valid: 0,
      cancel_valid: 0,
      status_name: "",
      note: null,
      redeem_item_id: 0,
      description: null,
      is_theme: 0,
      theme_primary_color: null,
      theme_secondary_color: null,
      theme_icon_color: null,
      theme_background: null,
      theme_background_page: 0
    }
  }
})

export const mutations = {
  UPDATE_REDEEM_SELECTED_ITEM: (state, payload) => {
    state.state.selectedItem = payload
  },
}

export const actions = {

}
export const getters = {
  getSelectedItem: state => {
    return state.state.selectedItem
  },
}

export default {
  state,
  actions,
  mutations,
  getters
}
